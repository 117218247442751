import React, { useMemo, useEffect } from "react";
import { useField } from "formik";
import { useModel } from "@wq/react";
import { Chip, Select } from "@wq/material";
import Avatar from "@material-ui/core/Avatar";

export default function ChipSelect(props) {
    const choices = useModel(props["wq:ForeignKey"]),
        mapping = useMemo(() => {
            const mapping = {};
            choices.forEach((row) => (mapping[row.id] = row));
            return mapping;
        }, [choices]),
        [, { value }, { setValue }] = useField(props.name);

    useEffect(() => {
        const filterValue = value.filter((code) => !!mapping[code]);
        if (filterValue.length < value.length) {
            setValue(filterValue);
        }
    }, [value, mapping]);

    return (
        <Select
            {...props}
            renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((code) => (
                        <Chip
                            key={code}
                            avatar={
                                <Avatar
                                    src={
                                        mapping[code]
                                            ? mapping[code].photo
                                            : null
                                    }
                                />
                            }
                            variant="outlined"
                            label={mapping[code] ? mapping[code].label : code}
                        />
                    ))}
                </div>
            )}
        />
    );
}
