import React, { useEffect } from "react";
import { useFormikContext, useField, getIn } from "formik";
import { useModel } from "@wq/react";
import { Input } from "@wq/material";

export default function ContactInput(props) {
    const { values } = useFormikContext(),
        contact = getIn(values, "last_entry.contact"),
        personId = getIn(values, "last_entry.person_id") || values.id,
        people = useModel("person"),
        person = personId && people.find((p) => p.id === personId),
        [, { value }, { setValue }] = useField(props.name);

    let isActive, personAttr;
    if (props.name.endsWith("email")) {
        isActive = contact === "email" || contact === "both";
        personAttr = "email";
    } else {
        isActive = contact === "cell" || contact === "both";
        personAttr = "phone_cell";
    }

    useEffect(() => {
        if (value || !person || !isActive) {
            return;
        }
        setValue(person[personAttr]);
    }, [value, isActive, person]);

    if (!isActive) {
        return null;
    }
    return <Input {...props} />;
}
