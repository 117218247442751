import config from "./data/config";

export default {
    ...config,
    backgroundSync: false,
    material: {
        theme: {
            primary: "#223355",
            // secondary: '#0dccb1'
        },
    },
    map: {
        bounds: [
            [44.7, -93.6],
            [45.2, -92.8],
        ],
    },
};
