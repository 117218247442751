import React from "react";
import { useModel, useRouteInfo, useIconComponents, useNav } from "@wq/react";
import {
    View,
    ScrollView,
    HorizontalView,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableTitle,
    Typography,
} from "@wq/material";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import PersonRow from "../components/PersonRow";
import SignoutButtons from "../components/SignoutButtons";

export default function PersonList() {
    const nav = useNav(),
        { params, path, parent_label, parent_id } = useRouteInfo(),
        { SignIn, All } = useIconComponents(),
        filter = {};

    if (params && params.signed_out === "true") {
        filter.signed_out = true;
    }

    if (parent_id) {
        filter.office_id = parent_id;
    }

    const list = useModel("person", filter),
        empty = list.length === 0,
        tab = filter.signed_out ? "signed_out" : "all";

    function handleTab() {
        if (tab === "signed_out") {
            nav(path);
        } else {
            nav(`${path}?signed_out=true`);
        }
    }
    return (
        <View
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
            }}
        >
            <Tabs
                value={tab}
                centered
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTab}
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
                <Tab
                    label="Who's Signed Out"
                    value="signed_out"
                    icon={<SignIn />}
                />
                <Tab label="All" value="all" icon={<All />} />
            </Tabs>
            <ScrollView>
                <Table style={{ backgroundColor: "white" }}>
                    <TableHead>
                        <TableRow>
                            <TableTitle></TableTitle>
                            <TableTitle>
                                <Hidden xsDown>Name</Hidden>
                            </TableTitle>
                            <Hidden mdDown>
                                <TableTitle>Office</TableTitle>
                            </Hidden>
                            <Hidden smDown>
                                <TableTitle>Signed Out</TableTitle>
                            </Hidden>
                            <Hidden xsDown>
                                <TableTitle>Return</TableTitle>
                            </Hidden>
                            <Hidden xsDown>
                                <TableTitle>Location</TableTitle>
                            </Hidden>
                            <Hidden smDown>
                                <TableTitle>Vehicle</TableTitle>
                            </Hidden>
                            <Hidden smDown>
                                <TableTitle>Contact</TableTitle>
                            </Hidden>
                            <Hidden mdDown>
                                <TableTitle>Notes</TableTitle>
                            </Hidden>
                            <TableTitle></TableTitle>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row) => (
                            <PersonRow
                                key={row.id}
                                {...row}
                                currentPath={path}
                            />
                        ))}
                    </TableBody>
                </Table>
                {empty && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ padding: "1em" }}
                    >
                        No one is signed out
                        {parent_label && ` from ${parent_label}`}.
                    </Typography>
                )}
            </ScrollView>
            {tab === "signed_out" && (
                <HorizontalView>
                    <View />
                    <SignoutButtons
                        person={{
                            id: null,
                            signed_out: false,
                            office_id: filter.office_id,
                        }}
                    />
                </HorizontalView>
            )}
        </View>
    );
}
