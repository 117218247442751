export default function context(ctx, routeInfo) {
    if (
        routeInfo.name !== "person_edit:new" &&
        routeInfo.name != "person_signout"
    ) {
        return;
    }
    const last_entry = ctx.last_entry || {};

    // Create timezone-offsetted date to take advantage of built in ISO string
    // format
    var today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
    var dateParts = today.toISOString().split("T");
    var timeParts = dateParts[1].split(":");
    var time, ampm;
    if (timeParts[0] > "12") {
        time = +timeParts[0] - 12 + ":" + timeParts[1];
        ampm = "PM";
    } else {
        time = +timeParts[0] + ":" + timeParts[1];
        if (timeParts[0] == "12") {
            ampm = "PM";
        } else {
            ampm = "AM";
        }
    }
    time += " " + ampm;

    const person_list =
        routeInfo.params && routeInfo.params.office_id
            ? () =>
                  last_entry.person_list
                      .call(ctx)
                      .filter(
                          (person) =>
                              !person.signed_out &&
                              person.office_id == routeInfo.params.office_id
                      )
            : last_entry.person_list;

    return {
        "last_entry.person_list": person_list,
        last_entry: {
            start_date: dateParts[0],
            start_time: time,
            person_list,
        },
    };
}
