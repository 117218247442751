import React from "react";
import { ScrollView, List, ListItemLink } from "@wq/material";

export default function Index() {
    return (
        <ScrollView>
            <List>
                <ListItemLink to="/sync" icon="pending">
                    Sync LDAP Users
                </ListItemLink>
                <ListItemLink to="/offices" icon="office">
                    Manage Office Notifications
                </ListItemLink>
            </List>
        </ScrollView>
    );
}
