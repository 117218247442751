import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    Link,
    useContextTitle,
    useComponents,
    usePluginState,
    useModel,
} from "@wq/react";
import { IconButton } from "@wq/material";
import Breadcrumbs from "./Breadcrumbs";

export default function Header() {
    const title = useContextTitle(),
        { user } = usePluginState("auth"),
        people = useModel("person"),
        me = user && people.find((person) => person.id === user.id);
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <img
                        src="/hei-logo.png"
                        style={{ height: 64, width: 146, marginRight: 16 }}
                    />
                    <Typography
                        variant="h6"
                        style={{
                            flex: 1,
                            fontFamily: "'Josefin Sans', sans-serif",
                            fontWeight: 400,
                        }}
                    >
                        {title === "New Person" ? "Sign Out Form" : title}
                    </Typography>
                    {me && me.is_superuser && (
                        <IconButton
                            component={Link}
                            to="/admin"
                            icon="admin"
                            color="inherit"
                        />
                    )}
                </Toolbar>
            </AppBar>
            <Breadcrumbs />
        </>
    );
}
