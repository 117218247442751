import React from "react";
import { Link } from "@wq/material";
import MuiLink from "@material-ui/core/Link";

export function InternalLink({ to, style, ...rest }) {
    return <Link to={to} style={style} {...rest} />;
}

export function ExternalLink({ href, style, ...rest }) {
    return <MuiLink href={href} style={style} {...rest} />;
}
