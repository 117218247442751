import React from "react";
import { useModel, usePluginState } from "@wq/react";
import { ScrollView, List, ListItemLink } from "@wq/material";

export default function OfficeList() {
    const offices = useModel("office"),
        people = useModel("person"),
        { user } = usePluginState("auth"),
        me = user && people.find((person) => person.id === user.id);

    return (
        <ScrollView>
            <List>
                {offices.map((office) => (
                    <ListItemLink
                        icon="office"
                        to={
                            me && me.is_superuser
                                ? `/offices/${office.id}/edit`
                                : `/offices/${office.id}/people?signed_out=true`
                        }
                    >
                        {office.label}
                    </ListItemLink>
                ))}
            </List>
        </ScrollView>
    );
}
