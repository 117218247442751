import React from "react";

import Paper from "@material-ui/core/Paper";
import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { makeStyles } from "@material-ui/core/styles";
import {
    useBreadcrumbs,
    useReverse,
    useComponents,
    useRenderContext,
    useModel,
} from "@wq/react";
import HomeLink from "./HomeLink";

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

export default function Breadcrumbs() {
    var links = useBreadcrumbs(),
        reverse = useReverse(),
        classes = useStyles(),
        context = useRenderContext(),
        offices = useModel("office"),
        { ButtonLink } = useComponents();

    if (!links) {
        links = [{ url: reverse("index"), label: "Home", active: true }];
    }

    const home = links[0],
        parentPage = links[links.length - 2] || home,
        currentPage = links[links.length - 1],
        path = typeof currentPage.url === "string" ? currentPage.url : "/";

    if (path.match("/offices/.*/people")) {
        currentPage.label = parentPage.label;
        links = [home, currentPage];
    } else if (links.length === 2 && currentPage.label === "People") {
        currentPage.label = "All Offices";
    } else if (parentPage.url.type === "PERSON_LIST" && context.office_id) {
        links = [
            home,
            {
                url: `/offices/${context.office_id}/people?signed_out=true`,
                label: context.office_label,
            },
            currentPage,
        ];
    } else if (links.length === 2 && currentPage.label === "New Person") {
        const office = offices.find((office) => office.id == context.office_id);
        currentPage.label = "Sign Out Form";
        if (context.office_id) {
            links = [
                home,
                {
                    url: `/offices/${context.office_id}/people?signed_out=true`,
                    label: office ? office.label : context.office_id,
                },
                currentPage,
            ];
        } else {
            links = [
                home,
                {
                    url: "/people?signed_out=true",
                    label: "All Offices",
                },
                currentPage,
            ];
        }
    } else if (currentPage.label === "signout") {
        currentPage.label = "Sign Out Form";
    } else if (
        currentPage.label === "Sync" ||
        currentPage.label === "Offices"
    ) {
        links = [home, { url: "/admin", label: "Administration" }, currentPage];
    } else if (parentPage.url.type.startsWith("OFFICE_")) {
        links = [
            home,
            { url: "/admin", label: "Administration" },
            ...links.slice(1),
        ];
    }

    // FIXME: NavLink should already be able to detect current page
    links[links.length - 1].active = true;

    return (
        <Paper elevation={0} className={classes.breadcrumbs} square>
            <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <HomeLink
                    to={links[0].url}
                    label={links[0].label}
                    active={links[0].active}
                />
                {links.slice(1).map(({ url, label, active }, i) => (
                    <ButtonLink
                        key={i}
                        to={url}
                        color={active ? "inherit" : "primary"}
                    >
                        {label}
                    </ButtonLink>
                ))}
            </MuiBreadcrumbs>
        </Paper>
    );
}
