import React from "react";
import { Form, Link } from "@wq/react";
import { SubmitButton, Button } from "@wq/material";

export default function SignoutButtons({ person }) {
    const { id, signed_out, office_id } = person;
    if (signed_out) {
        return (
            <Form action={`people/${id}/signin`} method="POST">
                <SubmitButton icon="sign-in">
                    <nobr>Sign In</nobr>
                </SubmitButton>
            </Form>
        );
    } else {
        return (
            <Button
                component={Link}
                icon="sign-out"
                to={
                    id
                        ? `/people/${id}/signout`
                        : `/people/new${
                              office_id ? "?office_id=" + office_id : ""
                          }`
                }
                variant="contained"
                color="primary"
            >
                <nobr>{id ? "Sign Out" : "Sign Someone Out..."}</nobr>
            </Button>
        );
    }
}
