export default {
    "pages": {
        "login": {
            "url": "login",
            "name": "login"
        },
        "logout": {
            "url": "logout",
            "name": "logout"
        },
        "index": {
            "url": "",
            "verbose_name": "Sign Out App",
            "name": "index"
        },
        "admin": {
            "url": "admin",
            "verbose_name": "Administration",
            "name": "admin"
        },
        "sync": {
            "url": "sync",
            "verbose_name": "Sync",
            "name": "sync"
        },
        "office": {
            "lookup": "code",
            "cache": "all",
            "postsave": "office_list",
            "name": "office",
            "url": "offices",
            "list": true,
            "form": [
                {
                    "name": "code",
                    "label": "Code",
                    "bind": {
                        "required": true
                    },
                    "wq:length": 50,
                    "type": "string"
                },
                {
                    "name": "name",
                    "label": "Name",
                    "bind": {
                        "required": true
                    },
                    "wq:length": 255,
                    "type": "string"
                },
                {
                    "name": "is_active",
                    "label": "Is Active",
                    "choices": [
                        {
                            "name": true,
                            "label": "Yes"
                        },
                        {
                            "name": false,
                            "label": "No"
                        }
                    ],
                    "type": "select one"
                },
                {
                    "name": "notify_people",
                    "label": "Notify People",
                    "bind": {
                        "required": true
                    },
                    "type": "select",
                    "wq:ForeignKey": "person",
                    "control": {
                        "appearance": "chip-select"
                    }
                }
            ],
            "verbose_name": "office",
            "verbose_name_plural": "offices",
            "ordering": [
                "name"
            ]
        },
        "person": {
            "lookup": "username",
            "cache": "all",
            "postsave": "offices/{{office_id}}/people?signed_out=true",
            "modes": [
                "list",
                "detail",
                "edit",
                "signout"
            ],
            "name": "person",
            "url": "people",
            "list": true,
            "form": [
                {
                    "name": "office",
                    "type": "hidden",
                    "wq:ForeignKey": "office",
                    "control": {
                        "appearance": "hidden"
                    }
                },
                {
                    "name": "last_entry",
                    "label": "Sign Out Form",
                    "type": "group",
                    "children": [
                        {
                            "name": "person",
                            "label": "Name",
                            "control": {
                                "appearance": "person-select"
                            },
                            "wq:ForeignKey": "person"
                        },
                        {
                            "name": "start_date",
                            "label": "Sign Out Date",
                            "wq:length": 100,
                            "type": "string",
                            "disabled": true
                        },
                        {
                            "name": "start_time",
                            "label": "Sign Out Time",
                            "wq:length": 100,
                            "type": "string",
                            "disabled": true
                        },
                        {
                            "name": "location",
                            "label": "Where Going?",
                            "type": "text"
                        },
                        {
                            "name": "contact",
                            "label": "How To Contact",
                            "choices": [
                                {
                                    "name": "email",
                                    "label": "Email"
                                },
                                {
                                    "name": "cell",
                                    "label": "Cell"
                                },
                                {
                                    "name": "both",
                                    "label": "Both"
                                }
                            ],
                            "type": "select one"
                        },
                        {
                            "name": "contact_email",
                            "label": "Contact Email",
                            "wq:length": 100,
                            "type": "string",
                            "control": {
                                "appearance": "contact-input"
                            }
                        },
                        {
                            "name": "contact_phone",
                            "label": "Contact Cell",
                            "wq:length": 100,
                            "type": "string",
                            "control": {
                                "appearance": "contact-input"
                            }
                        },
                        {
                            "name": "vehicle",
                            "label": "Vehicle",
                            "type": "text"
                        },
                        {
                            "name": "end_date",
                            "label": "Expected Return Date",
                            "wq:length": 100,
                            "type": "date"
                        },
                        {
                            "name": "end_time",
                            "label": "Expected Return Time",
                            "wq:length": 100,
                            "type": "string"
                        },
                        {
                            "name": "notes",
                            "label": "Notes",
                            "type": "text",
                            "multiline": true
                        }
                    ]
                }
            ],
            "verbose_name": "person",
            "verbose_name_plural": "people",
            "ordering": [
                "last_name",
                "first_name"
            ]
        }
    },
    "router": {
        "base_url": ""
    },
    "store": {
        "service": "",
        "defaults": {
            "format": "json"
        }
    }
};
