import app from "@wq/app";
import material from "@wq/material";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Index from "./views/IndexView";
import Admin from "./views/Admin";
import Sync from "./views/Sync";
import PersonList from "./views/PersonList";
import PersonDetail from "./views/PersonDetail";
import PersonSignout from "./views/PersonSignout";
import OfficeList from "./views/OfficeList";

import ContactInput from "./inputs/ContactInput";
import PersonSelect from "./inputs/PersonSelect";
import ChipSelect from "./inputs/ChipSelect";

import config from "./config";
import icons from "./icons";
import context from "./context";

app.use([material]);
app.use({
    components: { Header, Footer },
    views: {
        Index,
        Admin,
        Sync,
        PersonList,
        PersonDetail,
        PersonSignout,
        OfficeList,
    },
    inputs: { ContactInput, PersonSelect, ChipSelect },
    icons,
    context
});

app.init(config).then(function () {
    app.prefetchAll();
});

setInterval(async () => {
    app.spin.start();
    await app.models.person.prefetch();
    app.spin.stop();
    // app.refresh();
}, 1000 * 120);

if (config.debug) {
    window.wq = app;
}

// Clear data from Signout v0.5
localStorage.clear();
