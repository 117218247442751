import React from "react";
import {
    Link,
    useModel,
    usePluginState,
    useIconComponents,
    useViewComponents,
} from "@wq/react";
import { List, ScrollView, Table, TableBody } from "@wq/material";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import PersonRow from "../components/PersonRow";

export default function Index() {
    const { user } = usePluginState("auth"),
        offices = useModel("office"),
        people = useModel("person"),
        signedOut = { all: [] },
        { Login } = useViewComponents();

    if (!user) {
        return <Login />;
    }

    let me = people.find((person) => person.id === user.id) || user;

    offices.forEach((office) => (signedOut[office.id] = []));
    people
        .filter((person) => person.signed_out && signedOut[person.office_id])
        .forEach((person) => {
            signedOut[person.office_id].push(person);
            signedOut["all"].push(person);
        });

    return (
        <ScrollView>
            <Table>
                <TableBody>
                    <PersonRow {...me} />
                </TableBody>
            </Table>
            <List>
                <ListSubheader
                    style={{ backgroundColor: "#fafafa" }}
                ></ListSubheader>
                {offices.map((office) => (
                    <Office
                        key={office.id}
                        id={office.id}
                        label={office.label}
                        people={signedOut[office.id]}
                    />
                ))}

                <Office label="All Offices" people={signedOut["all"]} />
            </List>
        </ScrollView>
    );
}

export function Office({ id, label, people }) {
    const prefix = id ? `/offices/${id}` : "",
        { SignOut, SignIn } = useIconComponents();
    return (
        <ListItem
            button
            component={Link}
            to={`${prefix}/people?signed_out=true`}
        >
            <ListItemIcon>
                <SignIn />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
            <ListItemSecondaryAction>
                <Count count={people.length} />
                <IconButton
                    component={Link}
                    color="primary"
                    to={`/people/new${id ? "?office_id=" + id : ""}`}
                >
                    <SignOut />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function Count({ count }) {
    return (
        <span
            style={{
                border: "1px solid #bce",
                padding: "2px 4px",
                borderRadius: 4,
            }}
        >
            {count}
        </span>
    );
}

function FancyCount({ people }) {
    return (
        <AvatarGroup max={5}>
            {people.map((person) => (
                <Avatar key={person.id} src={person.photo} />
            ))}
        </AvatarGroup>
    );
}
