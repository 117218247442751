import React from "react";
import { Form, Link } from "@wq/react";
import { SubmitButton, Button, TableRow, TableCell } from "@wq/material";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import { ExternalLink, InternalLink } from "./Link";
import SignoutButtons from "./SignoutButtons";

export default function PersonRow(props) {
    const {
            id,
            photo,
            first_name,
            last_name,
            office_label,
            signed_out,
            last_entry,
            currentPath,
        } = props,
        {
            start_date,
            start_time,
            end_date,
            end_time,
            location,
            vehicle,
            contact,
            contact_email,
            contact_phone,
            notes,
        } = signed_out ? last_entry || {} : {};
    const contact_email_show =
            contact === "email" || contact === "both" ? (
                <ExternalLink href={"mailto:" + contact_email}>
                    {contact_email}
                </ExternalLink>
            ) : null,
        contact_phone_show =
            contact === "phone" || contact === "both" ? (
                <ExternalLink href={"tel:" + contact_phone}>
                    {contact_phone}
                </ExternalLink>
            ) : null;
    return (
        <TableRow>
            <TableCell>
                <InternalLink to={`/people/${id}`}>
                    <Avatar src={photo} />
                </InternalLink>
            </TableCell>
            <TableCell>
                <InternalLink to={`/people/${id}`}>
                    <strong>
                        <nobr>
                            {first_name} {last_name}
                        </nobr>
                    </strong>
                </InternalLink>
            </TableCell>
            <Hidden mdDown>
                <TableCell>{office_label}</TableCell>
            </Hidden>
            <Hidden smDown>
                <TableCell>
                    <nobr>{start_date}</nobr> <nobr>{start_time}</nobr>
                </TableCell>
            </Hidden>
            <Hidden xsDown>
                <TableCell>
                    <nobr>{end_date}</nobr> <nobr>{end_time}</nobr>
                </TableCell>
            </Hidden>
            <Hidden xsDown>
                <TableCell>{location}</TableCell>
            </Hidden>
            <Hidden smDown>
                <TableCell>{vehicle}</TableCell>
            </Hidden>
            <Hidden smDown>
                <TableCell>
                    {contact_email_show}
                    {contact === "both" && " / "}
                    {contact_phone_show}
                </TableCell>
            </Hidden>
            <Hidden mdDown>
                <TableCell>{notes}</TableCell>
            </Hidden>
            <TableCell style={{ textAlign: "right" }}>
                <SignoutButtons person={props} />
            </TableCell>
        </TableRow>
    );
}
