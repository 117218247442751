import React from "react";
import { useRouteInfo, useModel } from "@wq/react";
import {
    ScrollView,
    View,
    HorizontalView,
    Fab,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@wq/material";
import { ExternalLink } from "../components/Link";
import SignoutButtons from "../components/SignoutButtons";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function PersonDetail() {
    const { item_id } = useRouteInfo(),
        person = useModel("person", item_id) || {},
        last_entry = person.last_entry || {},
        isSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

    return (
        <>
            <ScrollView>
                <View
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1em",
                    }}
                >
                    <View
                        style={{
                            width: isSmall ? "100%" : "60%",
                            maxWidth: "30em",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{
                                fontFamily: "'Josefin Sans', sans-serif",
                                fontWeight: 400,
                            }}
                        >
                            {isSmall && (
                                <Avatar
                                    src={person.photo}
                                    style={{
                                        width: 48,
                                        height: 48,
                                        float: "right",
                                    }}
                                />
                            )}
                            {person.first_name} {person.last_name}
                        </Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Office</TableCell>
                                    <TableCell>{person.office_label}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>
                                        <ExternalLink
                                            href={`mailto:${person.email}`}
                                        >
                                            {person.email}
                                        </ExternalLink>
                                    </TableCell>
                                </TableRow>
                                {person.phone_main && (
                                    <TableRow>
                                        <TableCell>Main</TableCell>
                                        <TableCell>
                                            <ExternalLink
                                                href={`tel:${person.phone_main}`}
                                            >
                                                {person.phone_main}
                                            </ExternalLink>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {person.phone_cell && (
                                    <TableRow>
                                        <TableCell>Cell</TableCell>
                                        <TableCell>
                                            <ExternalLink
                                                href={`tel:${person.phone_cell}`}
                                            >
                                                {person.phone_cell}
                                            </ExternalLink>
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell colspan={2}>
                                        <SignoutButtons person={person} />
                                    </TableCell>
                                </TableRow>
                                {person.signed_out && (
                                    <>
                                        <TableRow>
                                            <TableCell>Signed Out</TableCell>
                                            <TableCell>
                                                {last_entry.start_date}{" "}
                                                {last_entry.start_time}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Expected Return
                                            </TableCell>
                                            <TableCell>
                                                {last_entry.end_date}{" "}
                                                {last_entry.end_time}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Location</TableCell>
                                            <TableCell>
                                                {last_entry.location}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Contact</TableCell>
                                            <TableCell>
                                                {last_entry.contact}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Vehicle</TableCell>
                                            <TableCell>
                                                {last_entry.vehicle}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Notes</TableCell>
                                            <TableCell>
                                                {last_entry.notes}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </View>
                    {!isSmall && (
                        <View>
                            <Avatar
                                style={{
                                    width: 256,
                                    height: 256,
                                }}
                                src={person.photo}
                            />
                        </View>
                    )}
                </View>
            </ScrollView>
        </>
    );
}
