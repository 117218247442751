import SignOut from "@material-ui/icons/Schedule";
import SignIn from "@material-ui/icons/Restore";
import All from "@material-ui/icons/People";
import Admin from "@material-ui/icons/Settings";
import Office from "@material-ui/icons/Business";

export default {
    SignOut,
    SignIn,
    All,
    Admin,
    Office,
};
