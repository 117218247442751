import React from "react";
import { useRouteInfo, useModel } from "@wq/react";
import { Select, HorizontalView, Typography } from "@wq/material";
import Avatar from "@material-ui/core/Avatar";

export default function PersonSelect(props) {
    const { item_id } = useRouteInfo();
    if (item_id) {
        return <PersonAvatar id={item_id} />;
    }
    return <Select {...props} required />;
}

function PersonAvatar({ id }) {
    const person = useModel("person", id);
    return (
        <HorizontalView>
            <Avatar src={person.photo} />
            <Typography
                style={{ flex: 1, marginLeft: "1em" }}
                variant="subtitle1"
            >
                {person.first_name} {person.last_name}
            </Typography>
        </HorizontalView>
    );
}
