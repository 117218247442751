import React from "react";
import { usePluginState } from "@wq/react";
import { Typography, HorizontalView } from "@wq/material";
import { ExternalLink, InternalLink } from "./Link";
import Hidden from "@material-ui/core/Hidden";
import { version } from "../../package.json";

export default function Footer() {
    const { user } = usePluginState("auth");
    return (
        <HorizontalView style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
            <Typography variant="caption" color="textSecondary">
                {user ? (
                    <>
                        Logged in as {user.username} |{" "}
                        <InternalLink to="/logout">
                            <strong>Log Out</strong>
                        </InternalLink>
                    </>
                ) : (
                    "Not logged in"
                )}
            </Typography>
            <Hidden xsDown>
                <Typography variant="caption" color="textSecondary">
                    Sign Out App v{version} | powered by{" "}
                    <ExternalLink
                        href="https://wq.io"
                        style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontWeight: "bold",
                        }}
                    >
                        wq
                    </ExternalLink>
                </Typography>
            </Hidden>
            <Typography variant="caption" color="textSecondary">
                <ExternalLink href="https://insidehei.houstoneng.com/">
                    <strong>InsideHEI</strong>
                </ExternalLink>
            </Typography>
        </HorizontalView>
    );
}
