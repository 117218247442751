import React, { useState, useEffect } from "react";
import { useIconComponents } from "@wq/react";
import { ScrollView, View, Typography } from "@wq/material";

export default function Sync() {
    const [taskId, setTaskId] = useState(null);
    useEffect(() => {
        if (taskId) {
            return;
        }
        async function start() {
            const response = await fetch("/sync/start?format=json"),
                data = await response.json();
            if (data && data.task_id) {
                setTaskId(data.task_id);
            }
        }
        start();
    }, [taskId]);

    if (taskId) {
        return <MonitorTask task={taskId} />;
    } else {
        return (
            <ScrollView>
                <View style={{ padding: "2em" }}>
                    <Typography variant="h6">Initializing...</Typography>
                </View>
            </ScrollView>
        );
    }
}

function MonitorTask({ task }) {
    const [info, setInfo] = useState({ status: "Started...", log: [] }),
        { Success, Pending } = useIconComponents();

    useEffect(() => {
        if (info.status === "SUCCESS") {
            return;
        }
        async function checkStatus() {
            const response = await fetch(
                    `/sync/status?format=json&task=${task}`
                ),
                result = await response.json();
            setInfo(result);
        }
        setTimeout(checkStatus, 1000);
    }, [task, info]);

    return (
        <ScrollView>
            <View style={{ padding: "2em" }}>
                <Typography variant="h6">
                    {info.status === "SUCCESS" ? <Success /> : <Pending />}
                    <span style={{ marginRight: "1em" }}>{info.status}</span>
                </Typography>
                {(info.log || []).map((log) => (
                    <Typography variant="body2">{log}</Typography>
                ))}
            </View>
        </ScrollView>
    );
}
